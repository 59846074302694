import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';

// const customStyles = {
//     rows: {
//         style: {
//             minHeight: '48px', // override the row height
//         },
//     },
//     headCells: {
//         style: {
//             paddingLeft: '8px', // override the cell padding for head cells
//             paddingRight: '8px',
//             backgroundColor: '#cee0eb', // set the background color for head cells
//         },
//     },
//     cells: {
//         style: {
//             paddingLeft: '8px', // override the cell padding for data cells
//             paddingRight: '8px',
//         },
//     },
// };

const Support = () => {
    const [supportType, setSupportType] = useState('');
    const [getSupportData, setGetSupportData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            wrap: true
        },
        {
            name: 'Support-Type',
            selector: row => row.typename,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.action,
            wrap: true
        }
    ];

    // >>>>Fetch Support Data<<<<
    const fetchSupportData = async () => {
        setIsLoading(true)
        const res = await HttpClientXml.requestData("get-support", "GET");
        console.log("4r1gt651df6", res?.data)
        setIsLoading(false)
        if (res && res?.status) {
            const arr = res?.data?.reverse()?.map((ele, ind) => ({
                sl: ind + 1,
                typename: ele?.typename,
                action: (
                    <>
                        <EditDeleteIcon
                            onClickEdit={(e) => handleEdit(ele)}
                            onClickDelete={(e) => handleDelete(ele?._id)}
                        />
                    </>
                ),
            }))
            setGetSupportData(arr)
        } else {
            setIsLoading(false)
            setGetSupportData([])
        }
    }

    // >>>>Handle edit<<<<
    const handleEdit = (item) => {
        if (item) {
            setIsEdit(true)
            setEditId(item?._id);
            setSupportType(item?.typename);
        } else {
            setIsEdit(false)
            setEditId("");
            setSupportType("");
        }
    };

    // >>>>Handle delete <<<<
    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: 'You are about to delete this Support type!',
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            confirmButtonColor: "#d33",
            cancelButtonText: "Cancel",
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            try {
                const res = await HttpClientXml.requestData(
                    `delete-support/${id}`,
                    "PUT"
                );
                Swal.fire("Deleted!", "Support type has been deleted.", "success");
                fetchSupportData();
            } catch (error) {
                Swal.fire("Error!", "Failed to delete Support type.", "error");
            }
        }
    };

    // >>>>Handle Submit<<<<
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!supportType) {
            toast.error('Support type is required.');
            return;
        }

        const data = {
            typename: supportType
        }

        if (isEdit) {
            setIsLoading(true)
            const res = await HttpClientXml.requestData(`update-support/${editId}`, "PUT", data);
            if (res && res?.status) {
                setIsLoading(false)
                toast.success(res?.message || "Support-type updated successfully.")
                setIsEdit(false)
                setSupportType("")
                fetchSupportData()
            } else {
                setIsLoading(false)
                return toast.error(res?.message || "Something went wrong...")
            }
        } else {
            setIsLoading(true)
            const res = await HttpClientXml.requestData("create-support", "POST", data);
            if (res && res?.status) {
                setIsLoading(false)
                toast.success(res?.message || "Support-type added successfully.")
                setSupportType("")
                fetchSupportData()
            } else {
                setIsLoading(false)
                return toast.error(res?.message || "Something went wrong...")
            }
        }
    };

    useEffect(() => {
        fetchSupportData()
    }, [])
    return (
        <div>

            <CustomLoader loading={isLoading} />
            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            {isEdit ? "Update Support-Type" : "Add Support-Type"}
                        </div>

                        <form className="mb-2 d-flex align-items-center " onSubmit={handleSubmit}>

                            {/* Support Type */}
                            <div className="flex-grow-1 me-2">
                                <label htmlFor="supportType" className='edit-tag'>
                                    Support Type<span className="text-danger">&nbsp;*</span>
                                </label>
                                <input
                                    type="text"
                                    id="supportType"
                                    value={supportType}
                                    onChange={(e) => setSupportType(e.target.value)}
                                    className="form-control w-75"
                                    placeholder='Support Type'
                                    name="supportType"
                                />
                            </div>

                            {/* Button */}
                            <div className='mt-4'>
                                <button
                                    type="submit"
                                    className={`${isEdit ? "btn btn-outline-primary" : "btn btn-outline-success"}`}
                                // style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    {isEdit ? "Update" : "Add"}
                                </button>
                            </div>

                        </form>

                        <hr className='mt-4 mb-5' />

                        <div>
                            <DataTable
                                columns={columns}
                                data={getSupportData}
                                pagination
                                striped
                            // customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>

    )
}

export default Support