import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import { Toast } from 'bootstrap';
import toast from 'react-hot-toast';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../loader/DeleteConfirmModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const Category = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [catName, setCatName] = useState()
    const [updateID, setupdateID] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [addSubCategories, setAddSubCategories] = useState("")
    const [categoryID, setCategoryID] = useState("")
    const [subCategoryData, setSubCategoryData] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [subCategoryId, setSubCategoryID] = useState('')

    // >>>>Toggle Modal-1<<<<
    const toggleModal = (id) => {
        setCategoryID(id)
        setModalOpen(!modalOpen);
        setAddSubCategories("")
    }

    // >>>>Toggle Modal-2<<<<
    const toggle = () => {
        setIsShow(!isShow);
    }

    // console.log(catName, updateID, "catName")

    // >>>>Coloum Declaration for DataTable-1<<<<
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
        },
        {
            name: 'Category Name',
            selector: row => row.Category,
        },
        {
            name: 'Date of Creation',
            selector: row => row.createdAt,
        },

        {
            name: 'Action',
            selector: row => row.action,
        }
    ];

    // >>>>Coloum Declaration for DataTable-2<<<<
    const columncategory = [
        {
            name: 'SL',
            selector: row => row.sl,
        },
        {
            name: 'Date',
            selector: row => row.date,
        },
        {
            name: 'Sub-Category Name',
            selector: row => row.subCategory,
        },

        {
            name: 'Action',
            selector: row => row.action,
        }
    ];

    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-all-service-category', "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                Category: item?.categoryName,
                createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),

                action: (
                    <div className='d-flex'>



                        {/* Add sub-categories  */}
                        <i class="fa-solid fa-layer-group" title='add sub-categories' onClick={() => {
                            toggleModal(item?._id);
                        }} style={{ marginTop: "8px", fontSize: "1.3rem", color: "#14532d", cursor: "pointer", marginRight: "8px" }}></i>

                        <EditDeleteIcon
                            onClickEdit={(e) => handleEdit(item)}
                            onClickDelete={(e) => handleDelete(item?._id)}
                        />

                    </div>
                )
            }));
        } else {
            setIsLoading(false);
            toast.error("hh")
        }
        setTableData(apiData?.reverse());
    }
    //add category field
    const handleChange = (e) => {
        // console.log("pallab", e.target.value)
        setCatName(e.target.value)
    }
    //Submit category
    const handleSubmit = async (e) => {
        // console.log("valuesdd");
        e.preventDefault();

        if (!catName) {
            return toast.error("Please put your Category Name");
        }

        const data = {
            categoryName: catName,

        }
        setIsLoading(true);
        const res = await HttpClientXml.requestData("post-service-category", "POST", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Link Added Successfully");
            setCatName("");
            setIsLoading(false);
            getCategoryData();

        } else {
            toast.error(res?.message);
            setIsLoading(false);
        }
    };
    //Edit Category
    const handleEdit = (item) => {
        // console.log(item,"item")
        setIsEdit(true)
        setCatName(item?.categoryName);
        setupdateID(item?._id)
    }
    // edit submit
    const handleEditSubmit = async (e) => {

        // console.log("valuesdd");
        e.preventDefault();

        if (!catName) {
            return toast.error("Please put updated Category Name");
        }

        const data = {
            categoryName: catName
        }
        setIsLoading(true);
        const res = await HttpClientXml.requestData("edit-service-category/" + updateID, "PUT", data);
        // console.log("resCat", res)
        if (res && res?.status) {
            toast.success("Link Added Successfully");
            setCatName("");
            // navigate('/manage-category');
            setIsLoading(false);
            setIsEdit(false)
            getCategoryData()
        } else {
            toast.error(res?.message);
            setIsLoading(false);
        }
    };
    // Delete
    const handleDelete = (id) => {

        const del = async () => {
            const data = {
                isDeleted: true
            }
            setIsLoading(true);
            const res = await HttpClientXml.requestData("edit-service-category/" + id, "PUT", data);
            // console.log("resCat", res)
            if (res && res?.status) {
                toast.success("Delete Successfully");

                setIsLoading(false);

                getCategoryData()
            } else {
                toast.error(res?.message);
                setIsLoading(false);
            }
        }
        DeleteConfirmModal(del);
    };


    // >>>>Fetch All Sub-Categories Data<<<<
    const fetchAllSubCategories = async () => {
        setIsLoading(true)
        const res = await HttpClientXml.requestData('subCategory', 'GET', {});
        setIsLoading(false)
        if (res) {
            const arr = res && res?.data?.reverse()?.map((ele, ind) => ({
                sl: ind + 1,
                date: moment(ele?.createdAt).format("DD-MM-YYYY"),
                subCategory: ele?.subCategoryName,
                action: (
                    <>
                        {/* Edit */}
                        <i class="fa-solid fa-pen-to-square" title='edit sub-category' style={{ marginTop: "8px", fontSize: "1.3rem", color: "#14532d", cursor: "pointer", marginRight: "8px" }} onClick={() => handleEditSubCategory(ele)}></i>

                        {/* Delete */}
                        <i class="fa-solid fa-trash-can" title='delete sub-category' style={{ marginTop: "8px", fontSize: "1.3rem", color: "#b91c1c", cursor: "pointer", marginRight: "8px" }} onClick={() => handleDeleteSubCategory(ele?._id)}></i>
                    </>
                )
            }))
            setSubCategoryData(arr)
        }
    }

    // >>>>Handle Delete Sub-Categories<<<<
    const handleDeleteSubCategory = async (id) => {
        if (id) {
            setIsLoading(true);
            const res = await HttpClientXml.requestData(`subCategory/${id}`, 'DELETE', {});
            setIsLoading(false)
            if (res) {
                toast.success("Sub-Category Deletd successfully")
                fetchAllSubCategories()
            }
        }
    }

    // >>>>Handle Edit Sub-Category<<<<
    const handleEditSubCategory = (ele) => {
        if (ele) {
            setSubCategoryID(ele?._id)
            setIsShow(true)
            setAddSubCategories(ele?.subCategoryName)
            toggle()
        } else {
            setSubCategoryID("")
            setIsShow(false)
            setAddSubCategories("")
            toggle()
        }
    }

    // >>>>Handle Add Sub-Categories<<<<
    const handleAddSubCategories = async () => {
        if (!addSubCategories) {
            return toast.error("Please fill the input...!")
        } else {
            const data = {
                categoryId: categoryID,
                subCategoryName: addSubCategories
            }
            console.log("5d4fs1", data)
            setIsLoading(true)
            const res = await HttpClientXml.requestData('subCategory', 'POST', data);
            setIsLoading(false)
            if (res) {
                toast.success(res?.message || "Sub-Category added successfully.!")
                getCategoryData()
                setModalOpen(false);
                fetchAllSubCategories()
            }
        }
    }

    // >>>>Handle Update Sub-Categories<<<<s
    const handleUpdateSubCategories = async () => {
        const id = subCategoryId
        if (!addSubCategories) {
            return toast.error("Please fill the input...!")
        } else {
            const data = {
                subCategoryName: addSubCategories
            }
            console.log("5d4fs1", data)
            setIsLoading(true)
            const res = await HttpClientXml.requestData(`subCategory/${id}`, 'PUT', data);
            setIsLoading(false)
            if (res) {
                toast.success(res?.message || "Sub-Category updated successfully.!")
                fetchAllSubCategories()
                // setModalOpen(false);
                setIsShow(false)
            }
        }
    }

    useEffect(() => {
        getCategoryData();
        fetchAllSubCategories()
    }, [])
    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Add & View Categories
                        </div>
                        <form className="mb-2">
                            <div className="row">



                                <div className="col-10 ">
                                    <label htmlFor="formGroupExampleInput" className='edit-tag'>Add Category<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Category Name'
                                        onChange={handleChange}
                                        value={catName}
                                        name="category"
                                    />
                                </div>


                                {!isEdit ? <div class="col-1 d-flex " style={{ marginTop: "31px" }}>

                                    <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        className="form-control btn-grad"
                                        style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                    >
                                        Add
                                    </button>


                                </div> :
                                    <div class="col-2 d-flex " style={{ marginTop: "31px" }}>

                                        <button
                                            type="submit"
                                            onClick={(e) => handleEditSubmit(e)}
                                            className="form-control btn-grad"
                                        //style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                        >
                                            Update
                                        </button>
                                        <ImCross
                                            onClick={(e) => { setIsEdit(false); setCatName('') }}
                                            style={{ fontSize: "30px", cursor: "pointer" }} className=" mx-3 mt-1 text-danger " />

                                    </div>}


                            </div>

                        </form>

                        {/* Categories DataTable */}
                        <div className='mt-5'>
                            <DataTable
                                title="Category"
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                                customStyles={customStyles}
                            />
                        </div>

                    </section>

                    {/* Sub-Categories DataTable */}
                    <div className='mt-5'>
                        <DataTable
                            title="Sub-Category"
                            columns={columncategory}
                            data={subCategoryData}
                            pagination
                            striped
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div >


            {/* modal-1 */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    Add Sub-Categories
                    {/* <button type="button" className="btn-close clsBTN" aria-label="Close" onClick={toggleModal}
                        style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            width: "100%",
                            maxWidth: "30px",
                            minWidth: "30px",
                            borderRadius: "10%",
                            border: "none",
                            zIndex: "3px",
                            fontSize: "17px"
                        }}
                    >X</button> */}
                </ModalHeader>
                <ModalBody>

                    <input type="text" value={addSubCategories} className="form-control" placeholder="Sub-Category Name" onChange={(e) => setAddSubCategories(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary' onClick={handleAddSubCategories}>Submit</button>
                </ModalFooter>
            </Modal>

            {/* modal-2 */}
            <Modal isOpen={isShow} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Update Sub-Categories
                    <button type="button" className="btn-close clsBTN" aria-label="Close" onClick={toggle}
                        style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            width: "100%",
                            maxWidth: "30px",
                            minWidth: "30px",
                            borderRadius: "10%",
                            border: "none",
                            zIndex: "3px",
                            fontSize: "17px"
                        }}
                    >X</button>
                </ModalHeader>
                <ModalBody>

                    <input type="text" value={addSubCategories} className="form-control" placeholder="Sub-Category Name" onChange={(e) => setAddSubCategories(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary' onClick={handleUpdateSubCategories}>Update</button>
                </ModalFooter>
            </Modal>

        </div >
    )
}

export default Category