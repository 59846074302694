import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import { Toast } from 'bootstrap';
import toast from 'react-hot-toast';
import moment from 'moment';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const SubscribedUser = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "4rem"
        },
        {
            name: 'User Name',
            selector: row => row.userName,
        },
        {
            name: 'Email',
            selector: row => row.Email,
        },
        {
            name: 'Phone Number',
            selector: row => row.Phone,
        },
        {
            name: 'Subscription Date',
            selector: row => row.SubscriptionDate,
        },
        {
            name: 'SubscriptionName',
            selector: row => row.SubscriptionName,
        },
        {
            name: 'Plan Price',
            selector: row => row.planBuyPrice,
        },
        // {
        //     name: 'Action',
        //     selector: row => row.action,
        // }
    ];
    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-user-with-plan-type', "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                userName: item?.userDetail?.username,
                Email: item?.userDetail?.email,
                Phone: item?.userDetail?.phone ? <div>+{item?.userDetail?.phone_country_code}&nbsp;{item?.userDetail?.phone}</div> : <div></div>,
                SubscriptionDate: moment(item?.userDetail?.createdAt).format("DD-MM-YYYY"),
                SubscriptionName: item?.subscriptionModel?.name,
                planBuyPrice: item?.planBuyPrice,
                // action: <EditDeleteIcon
                //     onClickEdit={(e) => handleEdit(item)}
                //     onClickDelete={(e) => handleDelete(item?._id)}
                // />
            }));
        } else {
            setIsLoading(false);
            toast.error("hh")
        }
        setTableData(apiData);
    }
    useEffect(() => {
        getCategoryData();
    }, [])
    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View Subscribed Users
                        </div>
                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                                customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>
            </div >




        </div >
    )
}

export default SubscribedUser