import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md'; // Import the icons
import CustomLoader from '../../loader/CustomLoader';
import { DeleteConfirmModal } from '../../loader/DeleteConfirmModal';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';
import { headLineStyle } from '../../Layout/HeadlineStyle';

const AddSubTask = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [allTaskData, setAllTaskData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const initValue = {
        projectId: "6553a034456189a1a37d4efe", // default project ID
        taskId: "",
        name: "",
        sub_task_id: "" // Added for tracking sub-task ID when editing
    };
    const [formValue, setFormValue] = useState(initValue);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue(prev => ({ ...prev, [name]: value }));
    };

    const validate = () => {
        if (!formValue.taskId) {
            toast.error("Task is Required!");
            return true;
        }
        if (!formValue.name) {
            toast.error("Sub Task Name is Required!");
            return true;
        }
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            return;
        }

        setIsLoading(true);

        const requestBody = {
            defaultTaskId: formValue.taskId, // Use selected task ID
            name: formValue.name, // Sub-task name
        };

        if (!isEdit) {
            // Add new sub-task
            try {
                const res = await HttpClientXml.requestData("sub-task-for-default-task", "POST", requestBody);
                setIsLoading(false);
                if (res?.status) {
                    toast.success("Added Successfully!");
                    setFormValue(initValue);
                    getSubTaskData();
                } else {
                    toast.error(res?.message || "Something went wrong");
                }
            } catch (error) {
                toast.error("An error occurred while adding subtask");
                setIsLoading(false);
            }
        } else {
            // Update existing sub-task
            try {
                const res = await HttpClientXml.requestData(
                    `sub-task-for-default-task/${formValue.sub_task_id}`, // Use the correct sub_task_id in the URL
                    "PUT",
                    requestBody
                );
                setIsLoading(false);
                if (res?.status) {
                    toast.success("Updated Successfully!");
                    setFormValue(initValue);
                    setIsEdit(false);
                    getSubTaskData(); // Reload sub-task data after successful update
                } else {
                    toast.error(res?.message || "Something went wrong");
                }
            } catch (error) {
                toast.error("An error occurred while updating subtask");
                setIsLoading(false);
            }
        }
    };

    const getTaskData = async () => {
        setIsLoading(true);
        try {
            const res = await HttpClientXml.requestData('taskName', 'GET');
            if (res?.status) {
                setAllTaskData(res?.data);
            } else {
                toast.error(res?.message || "Failed to fetch tasks");
            }
        } catch (error) {
            toast.error("An error occurred while fetching tasks");
        } finally {
            setIsLoading(false);
        }
    };

    const getSubTaskData = async () => {
        setIsLoading(true);
        try {
            const res = await HttpClientXml.requestData('sub-task-for-default-task', 'GET');
            if (res?.status) {
                setTableData(res?.data);
            } else {
                toast.error(res?.message || "Failed to fetch subtasks");
            }
        } catch (error) {
            toast.error("An error occurred while fetching subtasks");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (id) => {
        const del = async () => {
            setIsLoading(true);
            try {
                const res = await HttpClientXml.requestData(`sub-task-for-default-task/${id}`, 'DELETE');
                if (res?.status) {
                    toast.success("Deleted Successfully!");
                    getSubTaskData();
                } else {
                    toast.error(res?.message || "Something went wrong");
                }
            } catch (error) {
                toast.error("An error occurred while deleting");
            } finally {
                setIsLoading(false);
            }
        };
        DeleteConfirmModal(del);
    };

    const editHandler = (row) => {
        setFormValue({
            projectId: row.projectId,
            taskId: row?.taskDetails?._id, // Ensure the taskId is set for the corresponding sub-task
            name: row.name,
            sub_task_id: row._id // Set sub_task_id for updating
        });
        setIsEdit(true);
    };

    useEffect(() => {
        getTaskData();
        getSubTaskData();
    }, []);

    // Columns definition including edit and delete actions with icons
    const columns = [
        {
            name: 'Sub Task Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Task Name',
            selector: row => row?.taskDetails?.name
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex justify-content-between">
                    <button
                        className="btn btn-sm btn-outline-primary mx-2"
                        onClick={() => editHandler(row)}
                    >
                        <FaEdit /> {/* Edit Icon */}
                    </button>
                    <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => handleDelete(row._id)}
                    >
                        <MdDelete /> {/* Delete Icon */}
                    </button>
                </div>
            ),
        }
    ];

    return (
        <div>
            <CustomLoader loading={isLoading} />

            <div className="d-flex justify-content-end">
                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div style={headLineStyle} className="page-headline">
                            {isEdit ? "Update Sub Task" : "Add Sub Task"}
                        </div>
                        <form className="mb-2" onSubmit={handleSubmit}>
                            {/* Task Id */}
                            <div className="col-12">
                                <label htmlFor="formGroupExampleInput" className='edit-tag'>
                                    Select Task<span className="text-danger">&nbsp;*</span>
                                </label>
                                <select
                                    className="form-control"
                                    name="taskId"
                                    value={formValue.taskId} // This ensures the correct task is selected
                                    onChange={handleChange}
                                >
                                    <option value="">Select Task</option>
                                    {allTaskData.map((item) => (
                                        <option key={item._id} value={item._id}>{item.name}</option> // Ensure correct task name is shown
                                    ))}
                                </select>
                            </div>

                            {/* Sub Task Name */}
                            <div className="col-12 mt-3">
                                <label htmlFor="subTaskInput" className='edit-tag'>
                                    Sub Task Name<span className="text-danger">&nbsp;*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='Sub Task Name'
                                    onChange={handleChange}
                                    value={formValue.name}
                                    name="name"
                                    id='subTaskInput'
                                />
                            </div>

                            {/* Button */}
                            <div className="col-1 d-flex " style={{ marginTop: "31px" }}>
                                <button
                                    type="submit"
                                    className="btn"
                                    style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    {isEdit ? "Update" : "Add"}
                                </button>
                            </div>
                        </form>
                    </section>

                    {/* DataTable */}
                    <section className="piechartsBox_area">
                        <div style={headLineStyle} className="page-headline">
                            View Sub Task
                        </div>
                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default AddSubTask;
