import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader'
import HttpClientXml from '../../utils/HttpClientXml';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
            fontWeight: 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const ViewTickets = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [getTicketsData, setGetTicketsData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);

    // >>>>Coloum Declaration<<<<
    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            wrap: true
        },
        {
            name: 'Title',
            selector: row => row.title,
            wrap: true
        },
        {
            name: 'Descriptions',
            selector: row => row.description,
            wrap: true
        },
        // {
        //     name: 'Image',
        //     selector: row => row.image,
        //     wrap: true
        // },
        {
            name: 'Status',
            selector: row => row.ticketStatus,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.updateStatus,
            wrap: true
        },
        {
            name: 'View',
            selector: row => row.actionView,
            wrap: true
        }
    ];

    // >>>>Fetch Tickets Data<<<<
    const fetchTicketsData = async () => {
        setIsLoading(true)
        const res = await HttpClientXml.requestData("get-ticket", "GET");
        setIsLoading(false)
        if (res && res?.status) {
            const arr = res?.data?.reverse()?.map((ele, ind) => ({
                sl: ind + 1,
                title: ele?.title,
                description: ele?.description,
                // image: (
                //     <img
                //         src={ele?.image}
                //         alt="ticket image"
                //         style={{ width: "50px", height: "50px" }}
                //     />
                // ),
                ticketStatus: (
                    <p style={{ color: ele?.ticketStatus === "complete" ? "#198754" : "#6c757d", fontWeight: "bold", textTransform: "uppercase" }}>
                        {ele?.ticketStatus}
                    </p>
                ),
                updateStatus: (
                    <>
                        <div>
                            <button className='btn btn-outline-success' onClick={() => handleUpdateStatus(ele)}>Change</button>
                        </div>
                    </>
                ),
                actionView: (
                    <>
                        <span title="View"
                            onClick={() => handleView(ele)}
                        >
                            <i
                                className="fa-solid fa-eye"
                                style={{
                                    cursor: "pointer",
                                    marginRight: "5px",
                                    padding: "5px",
                                    fontSize: "1.5em",
                                    color: "darkblue"
                                    // color: "#ff9c00",
                                    // backgroundColor: "#fee5d2",
                                }}
                            ></i>
                        </span>
                    </>
                ),
            }))
            setGetTicketsData(arr)
        } else {
            setIsLoading(false)
            setGetTicketsData([])
        }
    }

    // >>>>Handle Update Status<<<<
    const handleUpdateStatus = async (ele) => {
        const data = {
            userID: ele?.userID,
            ticketID: ele?._id
        }
        setIsLoading(true)
        const res = await HttpClientXml.requestData("update-ticket-status", "POST", data);
        if (res && res?.status) {
            setIsLoading(false)
            toast.success(res?.message || "Status updated successfully.")
            fetchTicketsData()
        } else {
            setIsLoading(false)
            return toast.error(res?.message || "Something went wrong...")
        }
    }

    // >>>>Toogling the Modal<<<<
    const toggle = () => setModal(!modal);

    // >>>>Handle View<<<<
    const handleView = (ticket) => {
        console.log("LINE156", ticket.image);

        setSelectedTicket(ticket);
        toggle();
    };

    useEffect(() => {
        fetchTicketsData()
    }, [])

    return (
        <div>

            {/* Loader */}
            <CustomLoader loading={isLoading} />

            <div className="d-flex justify-content-end">
                <div className="form-header">
                    <section className="piechartsBox_area">

                        {/* Heading */}
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View Tickets
                        </div>

                        {/* Data Table */}
                        <div>
                            <DataTable
                                columns={columns}
                                data={getTicketsData}
                                pagination
                                striped
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </div>

                        {/* Modal for Viewing Ticket Details */}
                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>{selectedTicket?.title}</ModalHeader>
                            <ModalBody>
                                {selectedTicket && (
                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                    <strong>Description</strong>
                                                </td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                    {selectedTicket?.description}
                                                </td>
                                            </tr>
                                            {
                                                selectedTicket?.image !== "image"  && selectedTicket?.image.length > 0 && (
                                                    <>
                                                        <tr>
                                                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                                <strong>Image</strong>
                                                            </td>
                                                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                                {
                                                                    selectedTicket?.image?.map((img, i) => {
                                                                        return (
                                                                            <>
                                                                                <img
                                                                                    src={img?.data}
                                                                                    alt="ticket image"
                                                                                    style={{ width: "100%", height: "auto" }}
                                                                                />
                                                                            </>
                                                                        )
                                                                    })

                                                                }

                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle}>Close</Button>
                            </ModalFooter>
                        </Modal>

                    </section>
                </div>
            </div>
        </div>

    )
}

export default ViewTickets