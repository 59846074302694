import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import moment from 'moment';
import DetailsModal from '../../CustomComponents/Modal/DetailsModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip'

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', 
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', 
            paddingRight: '8px',
            backgroundColor: '#cee0eb', 
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
};
const Contractor = () => {
    const [tableData, setTableData] = useState([]);
    const [type, setType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);

    const [isBidModalOpen, setBidModalOpen] = useState(false);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [bidAmount, setBidAmount] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [CategoryData, setCategoryData] = useState([]);
    const [catId, setCatId] = useState(null)
    const [fromatCatData, setFromatCatData] = useState([])
    const [newCatFormat, setNewCatFormat] = useState([])


    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-all-user-acording-to-role', "GET", {});
        setType(res?.data?.[0]?.name);
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.[0]?.userList?.map((item, i) => {
                const fullCategoriesName = item?.categorydata?.map(ele => ele?.categoryName) || [];
                return {
                    sl: i + 1,
                    id: item?._id,
                    userName: item?.username,
                    Email: item?.email,
                    Phone: item?.phone ? <div>+{item?.phone_country_code}&nbsp;{item?.phone}</div> : <div></div>,
                    category: fullCategoriesName.length > 0 ? `${fullCategoriesName.slice(0, 3).join(', ')}...` : <p style={{ fontWeight: "bold" }}>No Category</p>,
                    fullCategoriesName,
                    limitAvailable: item?.userdata?.limitAvailable,
                    SubscriptionDate: moment(item?.userDetail?.createdAt).format("DD-MM-YYYY"),
                    SubscriptionName: item?.subscriptionModel?.name,
                    WalletBalance: item?.walletBalance,
                    increaseBid: (
                        <>
                            <button className='btn btn-outline-success' onClick={() => toggleBidModal(item)} style={{ padding: "0 8px" }}><span>+</span>Bid</button>
                        </>
                    ),
                    assignCategories: (
                        <>
                            <button className='btn btn-outline-success' onClick={() => toggleCategoryModal(item, fullCategoriesName)} style={{ padding: "0 5px" }}><span>+</span>Categories</button>
                        </>
                    )
                    // action: <EditDeleteIcon
                    //     onClickEdit={(e) => handleEdit(item)}
                    //     onClickDelete={(e) => handleDelete(item?._id)}
                    // />
                }
            });
        } else {
            setIsLoading(false);
            toast.error("hh")
        }
        setTableData(apiData);
    }

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "3rem"
        },
        {
            name: 'User Name',
            selector: row => row.userName,
            width: "7rem"

        },
        {
            name: 'Email',
            selector: row => row.Email,
            width: "12rem"
        },
        {
            name: 'Phone Number',
            selector: row => row.Phone,
            width: "9rem"

        },
        {
            name: 'Category',
            // selector: row => row.category,
            // width: "9rem"
            cell: row => (
                <div className="tooltipItem">
                    {row.category}
                    <span className="tooltipItemName">
                        <ol style={{ paddingLeft: "15px" }}>
                            {row?.fullCategoriesName && row?.fullCategoriesName?.length > 0 ?
                                row?.fullCategoriesName?.map((item, index) => (
                                    <li key={index} style={{ textAlign: "justify", borderBottom: "1px solid #ccc", padding: "5px 0" }}>{item}</li>
                                ))
                                :
                                <p className='d-flex align-item-center' style={{ textAlign: "justify", fontWeight: "bold", marginTop: "2rem", color: "#ccc" }}>No Categories Found</p>
                            }
                        </ol>
                    </span>
                </div>
            ),
            sortable: true,
            wrap: true

        },
        {
            name: 'Bid Amount',
            selector: row => row.limitAvailable,
            width: "8rem"

        },
        {
            name: 'Subscription Date',
            selector: row => row.SubscriptionDate,
            width: "8rem"

        },

        {
            name: 'Wallet Balance',
            selector: row => row.WalletBalance,
            width: "7rem"

        },
        {
            name: 'Payment History',

            cell: (row) => (
                <i className="fa-solid fa-eye " onClick={() => handleViewDetails(row.id)}
                    style={{ cursor: "pointer", color: 'darkBlue', fontSize: '15px' }}></i>
            ),
            width: "7rem"

        },

        {
            name: 'Increase Bid',
            selector: row => row.increaseBid,
            width: "7rem"

        },

        {
            name: 'Assign Categories',
            selector: row => row.assignCategories,
            width: "8rem"

        },
    ];

    // >>>>Fetch Category Data<<<<
    const fetchCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-all-service-category', "GET", {});
        if (res && res?.status) {
            const data = res?.data?.map(ele => ele?.categoryName)
            setFromatCatData(data)
            setCategoryData(res?.data);
        } else {
            setFromatCatData([])
            setCategoryData([])
        }
    }

    // >>>>Toggle Bid Amount Modal<<<<
    const toggleBidModal = (data) => {
        setBidModalOpen(!isBidModalOpen);
        if (data) {
            setCatId(data?._id)
        } else {
            setCatId(null)
        }
    };

    const formatData = CategoryData?.map((ele) => {
        return {
            value: ele?._id,
            label: ele?.categoryName
        };
    });

    useEffect(() => {
        const catData = newCatFormat
        const existingCategories = catData.map(cat => cat);
        const filteredCategories = formatData.filter(cat => !existingCategories.includes(cat?.label));
        setFromatCatData(filteredCategories);
    }, [isCategoryModalOpen]);

    // >>>>Toggle Categories Modal<<<<
    const toggleCategoryModal = (data, catData) => {
        // console.log("filteredCategoriessdf", CategoryData, formatData)
        setCategoryModalOpen(!isCategoryModalOpen);
        if (data && catData) {
            setCatId(data?._id);
            setNewCatFormat(catData);
        } else {
            setFromatCatData([]);
            setCatId(null);
        }
    };

    // MODAL
    const handleViewDetails = async (id) => {
        const res = await HttpClientXml.requestData('get-per-user-history/' + id, 'GET', {});
        console.log(res?.data, "kk")
        setSelectedDetails(res?.data);
        setIsModalOpen(true);
    };

    // >>>>Handle Bid Amount<<<<
    const handleBidSubmit = async () => {
        if (!bidAmount) {
            return toast.error("Please enter bid amount...");
        }

        if (parseFloat(bidAmount) <= 0) {
            return toast.error("Please enter valid bid amount...");
        }

        const data = {
            userID: catId,
            bid: parseFloat(bidAmount)
        };
        console.log('Bid amount submitted', data);

        setIsLoading(true);
        try {
            const res = await HttpClientXml.requestData('update-bid', "POST", data);
            if (res && res.status) {
                toast.success(res.message || "Bid amount added successfully.");
                setBidAmount('');
                setBidModalOpen(false);
                getCategoryData()
            } else {
                throw new Error(res?.message || "Something went wrong...");
            }
        } catch (error) {
            console.error("Error submitting bid:", error);
            toast.error(error.message || "An unexpected error occurred.");
            setBidAmount('');
            setBidModalOpen(false);
        } finally {
            setIsLoading(false);
        }
    };

    // >>>>Handle Assign Categories<<<<
    const handleCategorySubmit = async () => {

        if (!selectedCategory || selectedCategory?.length < 1) {
            return toast.error("Please select at least one category...");
        }

        const data = {
            userID: catId,
            categories: selectedCategory?.map(ele => ele?.value)
        }
        console.log('Bidamountsubmitted', data);
        // return
        setIsLoading(true)
        const res = await HttpClientXml.requestData('update-category', "POST", data);
        if (res && res?.status) {
            setIsLoading(false)
            toast.success(res?.message || "Categories added successfully.")
            setSelectedCategory(null);
            setCategoryModalOpen(false);
            getCategoryData()
        } else {
            setIsLoading(false)
            setSelectedCategory(null);
            setCategoryModalOpen(false);
            return toast.error("The following categories already exist.")
        }
    };

    useEffect(() => {
        getCategoryData();
        fetchCategoryData();
    }, [])

    return (
        <div>


            <div className="d-flex justify-content-end">


                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            {type}
                        </div>
                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                                customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>
            </div >

            <div>
                {/* Bid Modal */}
                <Modal isOpen={isBidModalOpen} toggle={() => setBidModalOpen(!isBidModalOpen)}>
                    <ModalHeader toggle={() => setBidModalOpen(!isBidModalOpen)}>Place a Bid</ModalHeader>
                    <ModalBody>
                        <Input
                            type="number"
                            value={bidAmount}
                            onChange={(e) => setBidAmount(e.target.value)}
                            placeholder="Enter bid amount"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleBidSubmit}>
                            Submit
                        </Button>
                        <Button color="secondary" onClick={() => setBidModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                {/* Categories Modal */}
                <Modal isOpen={isCategoryModalOpen} toggle={() => setCategoryModalOpen(!isCategoryModalOpen)}>
                    <ModalHeader toggle={() => setCategoryModalOpen(!isCategoryModalOpen)}>Assign Categories</ModalHeader>
                    <ModalBody>
                        <Select
                            value={selectedCategory}
                            onChange={setSelectedCategory}
                            options={fromatCatData}
                            isMulti
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => handleCategorySubmit()}>
                            Submit
                        </Button>
                        <Button color="secondary" onClick={() => setCategoryModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

            <DetailsModal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                details={selectedDetails}
            />
            <CustomLoader loading={isLoading} />

        </div >
    )
}

export default Contractor