import React, { useEffect, useState } from 'react'
import HttpClientXml from '../../utils/HttpClientXml';
import DataTable from 'react-data-table-component';
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import Papa from 'papaparse';
import moment from 'moment';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
            fontWeight: 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const Invitation = () => {
    const [getData, setGetData] = useState([])
    const [emails, setEmails] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const columns = [
        {
            name: 'Sl#',
            selector: row => row.sl,
            wrap: true
        },
        {
            name: 'Date',
            selector: row => row.sendDate,
            wrap: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            wrap: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            wrap: true
        },

        // {
        //     name: 'Action',
        //     selector: row => row.action,
        //     wrap: true
        // }
    ];

 // >>>>Fetch Support Data<<<<
 const sendEmails = async (data) => {
    setIsLoading(true)
    // const res = await HttpClientXml.post("invititation", data);
    const res = await HttpClientXml.requestData("invititation", "POST", data);

    if (res && res?.status) {
        setIsLoading(false)
        fetchData()
        // setGetData(arr)
    } else {
        setIsLoading(false)
        // setGetData([])
    }
}

    // >>>>Fetch Support Data<<<<
    const fetchData = async () => {
        setIsLoading(true)
        const res = await HttpClientXml.requestData("get-invititation", "GET");
        console.log("LINE73", res?.data)

        if (res && res?.status) {
            const arr = res?.data?.reverse()?.map((item, index) => ({
                sl: index + 1,
                // typename: ele?.typename,
                sendDate: moment(item?.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
                // moment(row.updatedOn).format("DD-MM-YYYY hh:mm A")
                name: item?.name,
                email: item?.email,
            }))
            setIsLoading(false)
            setGetData(arr)
        } else {
            setIsLoading(false)
            setGetData([])
        }
    }

    useEffect(()=>{
        fetchData()
    },[])

    useEffect(() => {
        console.log("LINE180", emails)
        //Call API
        
        // emails.length > 0 && emails.map((item,key)=>{
        //     console.log("LINE92",item.email,emails.length);
        // })
        emails.length > 0 && fetchData()
    }, [emails])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    //   const emailList = result.data.map(row => row.email).filter(Boolean);
                    const emailData = result.data.map(row => ({
                        name: row.name,
                        email: row.email
                    })).filter(row => row.email);

                    const data={
                        data:emailData
                    }
                    sendEmails(data)

                    setEmails(emailData);
                },
                error: (error) => {
                    console.error("Error parsing CSV file:", error);
                }
            });
        }
    };

    return (
        <div>

            <CustomLoader loading={isLoading} />
            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            Upload CSV & Send Invitation
                        </div>

                        <form className="mb-2 d-flex align-items-center "
                        // onSubmit={handleSubmit}
                        >

                            {/* Support Type */}
                            <div className="flex-grow-1 me-2">
                                <label htmlFor="supportType" className='edit-tag'>
                                    Upload CSV file<span className="text-danger">*&nbsp;</span>
                                </label>
                                {/* <input
                                    type="text"
                                    id="supportType"
                                    value={supportType}
                                    onChange={(e) => setSupportType(e.target.value)}
                                    className="form-control w-75"
                                    placeholder='Support Type'
                                    name="supportType"
                                /> */}
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                // className="form-control"
                                />
                            </div>

                            {/* Button */}
                            {/* <div className='mt-4'>
                                <button
                                    type="submit"
                                    className={`${isEdit ? "btn btn-outline-primary" : "btn btn-outline-success"}`}
                                style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    {isEdit ? "Update" : "Add"}
                                </button>
                            </div> */}

                        </form>

                        <hr className='mt-4 mb-5' />

                        <div>
                            <DataTable
                                columns={columns}
                                data={getData}
                                pagination
                                striped
                                title="Email List"
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>

    )
}

export default Invitation