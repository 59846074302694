import React from "react";
// import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../src/Images/BuildBita Logo/BuildBetaJPG.jpeg";
import { toast } from "react-hot-toast";

function Sidebar() {

  return (
    <>
      <section className="sidebarwrap">
        <div className="top_area">
          <button id="backbtnsidebar">
            <i className="fa-solid fa-arrow-left"></i>
          </button>

          <div className="logo_area">
            <div className="logo">
              <img
                src={logo}
                className="img-fluid rounded "
                alt="logo"
                style={{ width: "150px", height: "80px" }}
              />
              {/* <h1 style={{ color: "#fff" }}>Logo</h1> */}
            </div>
          </div>
        </div>

        <div className="sidebar_menu">
          <ul className="nav Menu_Nav accordion" id="sidemenu">
            {/*** dashboard Menu 1***/}
            <li className="menuline">
              <NavLink to="/" className="">
                <i className="fa-solid fa-bars"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {/***Menu 2***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead1">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu1"
                  aria-expanded="true"
                  aria-controls="sidemenu1"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Login</span>
                </Link>
              </div>
              <div
                id="sidemenu1"
                className="collapse"
                aria-labelledby="sidemenuhead1"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><Link to="/login"><i className="fa-solid fa-angles-right mr-2"></i>Get Login</Link></li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
            {/* </ul>
              </div>
            </li> */}

            {/*** User Menu 2  ***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead2">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu2"
                  aria-expanded="true"
                  aria-controls="sidemenu2"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>User</span>
                </Link>
              </div>
              <div
                id="sidemenu2"
                className="collapse"
                aria-labelledby="sidemenuhead2"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <NavLink to="/subscribed-user">
                      <i className="fa-solid fa-angles-right mr-2"></i>View
                      Subscribed User
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/all-user">
                      <i className="fa-solid fa-angles-right mr-2"></i>View All
                      User
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user-contractor">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Contractor
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user-homeowner">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Homeowner
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user-sub-contractor">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Sub Contractor
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user-financer">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Financer
                    </NavLink>
                  </li>
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/*** Category Menu 3***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead3">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu3"
                  aria-expanded="true"
                  aria-controls="sidemenu3"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Categories</span>
                </Link>
              </div>

              <div
                id="sidemenu3"
                className="collapse"
                aria-labelledby="sidemenuhead3"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/categories">
                      <i className="fa-solid fa-angles-right mr-2"></i>View
                      Categories
                    </Link>
                  </li>

                  {/* <li>
                    <Link to="/sub-categories">
                      <i className="fa-solid fa-angles-right mr-2"></i>View Sub-Categories
                    </Link>
                  </li> */}
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>


            <li className="menuline">
              <div className="menu-head" id="sidemenuhead0">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu0"
                  aria-expanded="true"
                  aria-controls="sidemenu0"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Subcategories</span>
                </Link>
              </div>

              <div
                id="sidemenu0"
                className="collapse"
                aria-labelledby="sidemenuhead0"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/subcategory">
                      <i className="fa-solid fa-angles-right mr-2"></i>View
                      Subcategories
                    </Link>
                  </li>
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/*** widral Menu 4***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead4">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu4"
                  aria-expanded="true"
                  aria-controls="sidemenu4"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Withdrawal</span>
                </Link>
              </div>
              <div
                id="sidemenu4"
                className="collapse"
                aria-labelledby="sidemenuhead4"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/withdrawal">
                      <i className="fa-solid fa-angles-right mr-2"></i>View
                      Withdrawal Request{" "}
                    </Link>
                  </li>
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/*** Project Menu 5***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead5">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu5"
                  aria-expanded="true"
                  aria-controls="sidemenu5"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Project</span>
                </Link>
              </div>
              <div
                id="sidemenu5"
                className="collapse"
                aria-labelledby="sidemenuhead5"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/projects">
                      <i className="fa-solid fa-angles-right mr-2"></i>View
                      Projects{" "}
                    </Link>
                  </li>
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/*** Add Task Menu 6***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead6">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu6"
                  aria-expanded="true"
                  aria-controls="sidemenu6"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Task</span>
                </Link>
              </div>
              <div
                id="sidemenu6"
                className="collapse"
                aria-labelledby="sidemenuhead6"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/add-task-name">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Add Task Name{" "}
                    </Link>
                  </li>
                </ul>
                <ul className="Submenu">
                  <li>
                    <Link to="/add-subtask-name">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Add SubTask Name{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/*** Template Menu 7***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead6">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu6"
                  aria-expanded="true"
                  aria-controls="sidemenu6"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Template</span>
                </Link>
              </div>
              <div
                id="sidemenu6"
                className="collapse"
                aria-labelledby="sidemenuhead6"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/template-types">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Types
                    </Link>
                  </li>
                </ul>
                <ul className="Submenu">
                  <li>
                    <Link to="/templates">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Create Template
                    </Link>
                  </li>
                  <li>
                    <Link to="/manage-templates">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Manage Templates
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/*** Support Menu 7***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead7">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu7"
                  aria-expanded="true"
                  aria-controls="sidemenu7"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Support</span>
                </Link>
              </div>
              <div
                id="sidemenu7"
                className="collapse"
                aria-labelledby="sidemenuhead7"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/add-support-type">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Add Support Type{" "}
                    </Link>
                  </li>

                  <li>
                    <Link to="/view-tickets">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      View Tickets{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/*** Invitation Menu 8***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead7">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu8"
                  aria-expanded="true"
                  aria-controls="sidemenu8"
                >
                  <i className="fa-regular fa-address-card"></i>
                  <span>Invitation</span>
                </Link>
              </div>
              <div
                id="sidemenu8"
                className="collapse"
                aria-labelledby="sidemenuhead8"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/invitation">
                      <i className="fa-solid fa-angles-right mr-2"></i>
                      Send via CSV
                    </Link>
                  </li>

                  
                </ul>
              </div>
            </li>

          </ul>

          <ul className="nav Account_Nav">
            <div style={{ width: "100%" }}>
              <Link>
                <i className="fa-solid fa-right-from-bracket mr-2"></i>
                <span>Logout</span>
              </Link>
            </div>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
