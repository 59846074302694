import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import HttpClient from "../utils/HttpClient";
// import { useSelector } from "react-redux";
// import CommentModal from "./Modal/CommentModal";
// import ChattModel from "./Modal/ChattModel";

function RecentlyBiddedProject({ productData, getroledata, isFinance, setprojectId, setchatFlag }) {
    const randomUrl = "https://www.rotarybangkok.org/wp-content/uploads/2017/11/user-placeholder.d2a3ff8.png"


    // const { userData } = useSelector((state) => state.User);
    const [userData, setUserData] = useState()
    const [chattId, setChattID] = useState({ bidderid: "", projectid: "" })
    // const[projectid,setProjectId]=useState("")


    console.log("productDatall", productData,);
    const navigate = useNavigate();

    const handleId = (e, id) => {
        e.preventDefault();
        console.log("productID", id);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // return false
    return (
        <>
            <section className="recentlybidded_sec">
                <div className="head_tag">
                    {/* <div className="name">
            <h4>All posted services</h4>
          </div> */}
                    <div className="SeeAll_btn">
                        {/* <button className='btn'>See All</button> */}
                    </div>
                </div>
                <div className="allcard_wrap">
                    {
                        productData && productData?.map((it, ind) => {
                            // let index = it?.bidData?.findIndex((it)=>it.bidderData._id == userData._id)
                            // console.log("productData", it?._id);
                            console.log('itemjjjj', it?.userData?.image)
                            // if (it?.userData?.image) {
                            //   alert("no image")
                            // }
                            return (
                                <div className="card_box" key={ind}>
                                    <div className="d-flex justify-content-between" style={{ margin: "5px 0px" }}>
                                        <div className="d-flex ">

                                            <img
                                                src={
                                                    it?.userData?.image ??
                                                    randomUrl
                                                }
                                                style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    borderRadius: "50px",
                                                    border: "1px solid",
                                                }}
                                                alt=""
                                            />
                                            <div>
                                                <h6 style={{ margin: "5px 0px 0px 5px" }}>
                                                    {it?.userData?.username}
                                                </h6>
                                                <h6 style={{ fontSize: "10px", marginLeft: "8px" }}>
                                                    <i className="fa-regular fa-clock mr-2"></i>
                                                    {moment(it?.createdAt).fromNow()}
                                                </h6>
                                            </div>
                                        </div>
                                        <h6
                                            style={{ color: it?.acceptedStatus == 0 ? "green" : "red" }}
                                        >
                                            {" "}
                                            {it?.acceptedStatus == 0 ? "Open" : "Awarded"}
                                        </h6>
                                    </div>
                                    <div className="top_">
                                        <div className="head">

                                            <h4>{it?.name.slice(0, 180)}</h4>
                                        </div>
                                        {/* <div className='action_fun'> */}
                                        {/* <h6
                    style={{ color: it?.acceptedStatus == 0 ? "green" : "red" }}
                  >
                    {" "}
                    {it?.acceptedStatus == 0 ? "Open" : "Awarded"}
                  </h6> */}
                                        {/* </div> */}

                                    </div>
                                    {
                                        it?.image?.length > 0 ?

                                            <img
                                                src={it?.image[0]}
                                                style={{
                                                    height: "250px",
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    marginTop: "10px",
                                                }}
                                                alt=""
                                            /> : null}
                                    <div className="bottom_area" style={{ margin: "10px 0px" }}>
                                        <div className="right">
                                            <div className="d-flex align-items-center">
                                                <span>

                                                    {isFinance ? "£" : ""}  {isFinance ? it?.amount ?? `£${it?.startCost} - £${it?.endCost}` : `£${it?.startCost} - £${it?.endCost}`}


                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="text">
                  {it?.categories?.map((item, index) => {
                    return <p key={index}>{item} ,</p>;
                  })}
                </div> */}

                                    <div className="bottom_"   >
                                        <button className="Attachment_btn btn" onClick={() => navigate("/single-project/" + it?._id, { state: "showbodder" })}>
                                            <i className="fa-regular fa-note-sticky mr-2"></i>{" "}
                                            {it?.bidData?.length} Total Bid
                                        </button>
                                        <button className="Attachment_btn btn">
                                            <i className="fa-solid fa-link mr-2"></i> Attachment{" "}
                                            {it?.image?.length}
                                        </button>
                                        <button className="Attachment_btn btn">
                                            <i className="fa-solid fa-eye mr-2"></i>
                                            {it?.viewcount}
                                        </button>


                                    </div>
                                    <div className="top_">
                                        <div className="head"></div>

                                        <div className="action_fun">
                                            <button
                                                className="Viewprofile_btn"
                                                style={{ border: "0px" }}
                                                onClick={() => navigate("/single-project/" + it?._id)}
                                            >
                                                View Project
                                            </button>
                                        </div>

                                        {/* {getroledata == "Homeowner" ? null : it?.userData?._id ==
                                        userData?._id ? (
                                        <div className="action_fun">
                                            <button
                                                className="Viewprofile_btn"
                                                style={{ border: "0px" }}
                                                onClick={() => navigate("/single-project/" + it?._id)}
                                            >
                                                View Project
                                            </button>
                                        </div>
                                    ) : it?.acceptedStatus == 0 ? (
                                        it?.bidData?.findIndex(
                                            (it) => it.bidderData?._id == userData?._id
                                        ) >= 0 ? (
                                            <div className="action_fun">
                                                <button
                                                    className="Viewprofile_btn"
                                                    style={{ border: "0px" }}
                                                    onClick={() =>
                                                        navigate("/bidProject-details/" + it?._id)
                                                    }
                                                >
                                                    Already Bidded
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="action_fun">
                                                <button
                                                    className="Active_btn btn"
                                                    type="button"
                                                    onClick={() =>
                                                        navigate("/bidProject-details/" + it?._id)
                                                    }
                                                >
                                                    {isFinance ? "View " : "Bid Now"}
                                                </button>
                                            </div>
                                        )
                                    ) : null} */}
                                    </div>
                                </div>
                            );
                        })}
                    {/* <div className='card_box'>
                        <div className='top_'>
                            <div className='head'>
                                <h4>Construction Project Lorem ipsum dolor sit.</h4>
                            </div>
                            <div className='action_fun'>
                                <button className='Active_btn btn' style={{ background: "rgba(255, 96, 124, 0.14)", color: "#E60621", }}>Awarded</button>
                                <button className='Moredot_btn btn'><i className="fa-solid fa-ellipsis"></i></button>
                            </div>
                        </div>
                        <div className='text'>
                            <p>We are seeking an experienced and skilled builders for our home renovation. If you're passionate about transforming homes and creating spaces that inspire, I want to hear from you.....</p>
                        </div>

                        <div className='bottom_'>
                            <button className='Attachment_btn btn'><i className="fa-regular fa-note-sticky mr-2"></i> 25 Total Bid</button>
                            <button className='Attachment_btn btn'><i className="fa-solid fa-link mr-2"></i> Attachment 2</button>
                            <button className='Attachment_btn btn'><i className="fa-regular fa-clock mr-2"></i> 16 Hours  ago</button>
                        </div>
                    </div> */}
                </div>

            </section>
        </>
    );
}

export default RecentlyBiddedProject;
