import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import moment from 'moment';
import DetailsModal from '../../CustomComponents/Modal/DetailsModal';

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const SubContractor = () => {
    const [tableData, setTableData] = useState([]);
    const [type, setType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "5rem"
        },
        {
            name: 'User Name',
            selector: row => row.userName,
        },
        {
            name: 'Email',
            selector: row => row.Email,
            width: "15rem"
        },
        {
            name: 'Phone Number',
            selector: row => row.Phone,
        },
        {
            name: 'Subscription Date',
            selector: row => row.SubscriptionDate,
        },
        // {
        //     name: 'SubscriptionName',
        //     selector: row => row.SubscriptionName,
        // },
        {
            name: 'Wallet Balance',
            selector: row => row.WalletBalance,
        },
        {
            name: 'Payment History',

            cell: (row) => (
                <i className="fa-solid fa-eye " onClick={() => handleViewDetails(row.id)}
                    style={{ cursor: "pointer", color: 'darkBlue', fontSize: '15px' }}></i>
            ),
        },
    ];
    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-all-user-acording-to-role', "GET", {});
        console.log("resGetCat", res?.data?.[2]?.userList)
        setType(res?.data?.[2]?.name);
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.[2]?.userList?.map((item, i) => ({

                sl: i + 1,
                id: item?._id,
                userName: item?.username,
                Email: item?.email,
                Phone: item?.phone ? <div>+{item?.phone_country_code}&nbsp;{item?.phone}</div> : <div></div>,
                SubscriptionDate: moment(item?.userDetail?.createdAt).format("DD-MM-YYYY"),
                SubscriptionName: item?.subscriptionModel?.name,
                WalletBalance: item?.walletBalance,
                // action: <EditDeleteIcon
                //     onClickEdit={(e) => handleEdit(item)}
                //     onClickDelete={(e) => handleDelete(item?._id)}
                // />
            }));
        } else {
            setIsLoading(false);
            toast.error("hh")
        }
        setTableData(apiData);
    }
    // MODAL
    const handleViewDetails = async (id) => {
        console.log(id, "row")
        const res = await HttpClientXml.requestData('get-per-user-history/' + id, 'GET', {});
        console.log(res?.data, "kk")
        setSelectedDetails(res?.data);
        setIsModalOpen(true);
    };
    useEffect(() => {
        getCategoryData();
    }, [])
    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            {/* {type} */}
                            Financier
                        </div>
                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                                customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>
            </div >


            <DetailsModal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                details={selectedDetails}
            />


        </div >
    )
}

export default SubContractor